// in src/App.js
import * as React from "react";
import {fetchUtils, Admin, Resource, ListGuesser, Layout} from 'react-admin';
import simpleRestProvider from './dataProvider';
import memberList from './members/memberList'
import dashboard from "./main/main";
import UserIcon from '@mui/icons-material/People';
import authProvider from './authProvider/authProvider';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import API_SERVER_ADDRESS from "./settings";


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const httpClient = (url, options = {}) => {
    options.user = {
        authenticated: true,
        token: "Bearer " + localStorage.getItem('auth')
    };
    return fetchUtils.fetchJson(url, options);
};
const dataProvider = simpleRestProvider(API_SERVER_ADDRESS+'/api/v1', httpClient);

const App = () => (
    <Admin theme={darkTheme} title="복세편살 길드 관리 시스템" dataProvider={dataProvider} authProvider={authProvider} >
        <Resource name="dashboard" list={dashboard}/>
        <Resource name="members" list={memberList} icon={UserIcon}/>
    </Admin>
);

export default App;