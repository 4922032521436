import {TextInput, List, Datagrid, TextField, DateField, BooleanField, Pagination} from 'react-admin';

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    <TextInput label="Title" source="title" defaultValue="Hello, World!"/>,
];
const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
export const memberList = () => (
    <List filters={postFilters} pagination={<PostPagination/>} perPage={30}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="battleTag"/>
            <TextField source="name"/>
            <DateField source="createdAt"/>
        </Datagrid>
    </List>
);

export default memberList;