import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
export default dashboard => (
    <Card>
        <Title title="복세편살 길드 관리 시스템" />
        <CardContent>이것저것 추가 중</CardContent>
    </Card>
);
